
.card-dotted {
  background: #fff;
  border: 2px dashed #e2e9e9;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none !important;
  .icon {
    color: #e2e9e9;
  }
  &:hover {
    border: 2px dashed #209cee;
    color: #209cee;
  }
}
.dropdown {
  float: right;
}
.trigger-btn {
  font-size: 0.8rem;
}
